<template>
  <div class="relative bg-no-repeat bg-cover bg-center mt-8 lg:mt-14 animate__animated animate__fadeIn animate__delay-1s" :style="{ backgroundImage: `url(${require('@/assets/support-background.jpg')})` }">
    <div class="absolute inset-0 bg-gradient-to-r from-black"></div>
    <div class="relative max-w-lg-secondary mx-auto px-8 py-10 flex flex-col gap-6 xl:gap-8 xl:py-20 xl:pl-10">
      <h2 class="text-white upperccase sm:w-3/4 lg:w-3/4 xl:w-1/2" v-html="transifex.supportSection.title"></h2>
      <p class="-mt-2 text-xs font-bold sm:w-3/4 md:text-lg md:leading-5 xl:w-[43rem]" v-html="transifex.supportSection.description"></p>
      <div class="inline-flex">
        <a href="https://cliburn.org/support/" target="_blank" class="btn-primary" v-html="transifex.supportSection.btn"></a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "support-section",
  props: {
    transifex: null,
  }
}
</script>

<style lang="scss">
</style>