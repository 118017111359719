<template>
  <div v-if="currentLive">
      <PlayerLive
          :currentLive="currentLive"
          :transifex="transifex"
          :isOnEmbed="true"
      />
  </div>
  <div v-else class="relative h-screen	flex flex-col justify-center items-center text-black">
    <img
      src="@/assets/details-background.avif"
      class="absolute z-0 inset-0 object-cover w-full h-screen"
      alt="details-background"
    />
    <div class="absolute z-10 inset-0 bg-black opacity-50">

    </div>
    <div class="relative z-20 text-center text-white text-xl font-medium md:text-3xl">
      <div v-if="nextLive" class="mb-2">
        <Countdown :nextLive="nextLive" :transifex="transifex"/>
      </div>
      <div v-html="transifex.countdown.enjoyMore"></div>
    </div>
  </div>
  <LiveInforModal :transifex="transifex" :currentLive="currentLive"></LiveInforModal>
</template>

<script>
import PlayerLive from "@/components/player/PlayerLive";
import Countdown from "@/components/player/Countdown";
import LiveInforModal from "@/components/LiveInforModal";
import axios from "axios";
import {brahmsWebSocket} from "@/js/brahms_websocket";

export default {
  name: "player-embed-wrapper",
  components: {
    PlayerLive,
    Countdown,
    LiveInforModal
},
  data() {
    return {
      currentLive: null,
      nextLive: null
    }
  },
  props: {
    apiRoute: null,
    apiRouteNextLives: null,
    transifex: null,
    webSocketUrl: null,
    siteName: null,
  },
async mounted() {
    await this.getCurrentLive()
    const subscribeAction = {action: "subscribe", payload: "brahms.live"};
    brahmsWebSocket(this.webSocketUrl, subscribeAction, this.onMessageWSCallBack)
  },
  methods: {
    onMessageWSCallBack(event) {
      const updatedLiveId = JSON.parse(event.data).live_id
      const site = JSON.parse(event.data).site
      // Get a random timeout between 10000 -> 15000 miliseconds
      // For limit the request comme all in same time
      let timeout = Math.floor(((Math.random() * 10 + 1)/2 + 10)* 1000)
      if (site === this.siteName) {
        if (this.currentLive) {
          if (this.currentLive.id === updatedLiveId)
            setTimeout(async () => await this.getCurrentLive()
                , timeout);
        } else {
          // No currentLive -> call api /lives
          setTimeout(async () => await this.getCurrentLive()
              , timeout);
        }
      }
    },
    async getCurrentLive() {
      const {data} = await axios.get(this.apiRoute);
      if (data.count > 0) {
        this.currentLive = data.results[0]
      } else {
        this.currentLive = null
        const {data} = await axios.get(this.apiRouteNextLives);
        if (data.count > 0) {
          this.nextLive = data.results[0]
        }
      }
    }
  },
}
</script>

<style lang="scss">
.embed-link {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}
</style>