<template>
  <div class="mt-4">
    <div v-if="poll">
      <p v-if="poll.is_open_soon" class="font-bold text-primary">{{transifex.vote.beginAt}} {{poll.start_date}} {{transifex.vote.endAt}} {{poll.end_date}}</p>
      <p v-if="poll.is_active" class="font-bold text-primary">{{transifex.vote.voteUntil}} {{poll.end_date}}</p>
      <p v-if="poll.is_closed" class="font-bold text-primary">{{transifex.vote.voteEnded}}</p>
      <div v-if="votedCandidate" class="pt-6 font-bold">
        <p>{{transifex.vote.votedFor}} {{votedCandidate.title}}</p>
        <p>{{transifex.vote.thanks}}</p>
        <p>{{transifex.vote.nextVoting}} <span id="data-countdown-date"></span></p>
      </div>
      <div class="grid grid-cols-2 gap-6 pt-7 md:pt-11 md:grid-cols-3 md:gap-12">
        <div v-for="(candidate, index) in poll.candidates" :key="index">
          <div class="group relative inline-block w-full">
            <img :src="getImage(candidate)" class="object-cover w-full h-44 md:h-60 xl:h-88" :alt="candidate.title">
            <div @click="showVoteModal(candidate)"
                 class="absolute cursor-pointer items-center justify-center inset-0 opacity-0 flex items-end transition-all ease-in-out group-hover:opacity-100" :style="{backgroundColor: 'rgba(17, 110, 150, .6)'}">
              <div class="mt-10 font-bold text-sm md:text-base text-white lg:text-2xl">{{transifex.vote.btn}} {{candidate.title}}</div>
            </div>
          </div>
          <div class="mt-1">
            <p class="font-bold text-sm md:text-base lg:text-2xl">{{candidate.title}}</p>
          </div>
<!--          <button type="button" class="btn-secondary py-1.5" @click="showVoteModal(candidate)" :disabled="!!votedCandidate&&poll.is_active">{{transifex.vote.btn}}</button>-->
        </div>
      </div>
    </div>
    <VoteModal :candidate="selectedCandidate" :poll="poll" :apiRegisterVote="apiRegisterVote" :transifex="transifex"></VoteModal>
  </div>
</template>

<script>
import moment from 'moment';
import axios from "axios";
import VoteModal from "@/components/VoteModal";
import {appUtils} from "@/js/utils";

export default {
  name: "vote-page",
  props: {
    apiRoute: null,
    apiRegisterVote: null,
    transifex: null
  },
  components: {
    VoteModal
  },
  data() {
    return {
      poll: null,
      modal: null,
      selectedCandidate: null,
      votedCandidate: null
    }
  },
  async beforeCreate() {
    const {data} = await axios.get(this.apiRoute);
    if (data.count > 0) {
      this.poll = data.results[0]
      this.poll.start_date = moment(this.poll.start_date).format('LLL')
      this.poll.end_date = moment(this.poll.end_date).format('LLL')
      this.poll.candidates = []
      if(this.poll.poll_candidates.length > 0) {
        // Transform poll_candidate to candidate
        this.poll.poll_candidates.map(el => {
          this.poll.candidates.push(el.candidate)
        })
      }
    }
  },
  mounted() {
    this.modal = document.getElementById("modalVote")
    this.votedCandidate = this.getVotedCandidate()
    if(this.votedCandidate) {
      let available_date = moment(this.votedCandidate.voting_date).add(1, 'd');
      let isAfterNow = available_date.isAfter(moment.tz().format());
      if (isAfterNow) {
        appUtils.countdown(available_date, "data-countdown-date",
            this.transifex, this.callbackAt0, true)
      } else {
        this.votedCandidate = null
      }
    }
  },
  methods: {
    callbackAt0() {
      console.log('vote available now !')
      location.reload()
    },
    showVoteModal(candidate) {
      this.selectedCandidate = candidate
      this.modal.style.display = "block"
    },
    getVotedCandidate() {
      let candidate = window.localStorage.getItem('voted_cliburn_2022');
      if(candidate)
        return JSON.parse(candidate)
      return null
    },
    getImage(item) {
      if (document.body.clientWidth < 525) {
        return item.image
      }
      return item.image_16_9
    }
  }
}
</script>

<style lang="scss">
</style>