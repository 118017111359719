<template>
  <div class="relative bg-no-repeat bg-cover bg-center mt-8 lg:mt-14 animate__animated animate__fadeIn animate__delay-1s" :style="{ backgroundImage: `url(${require('@/assets/newsletter-background.jpg')})` }">
    <div class="absolute inset-0 bg-gradient-to-b from-black md:bg-gradient-to-r md:right-1/4"></div>
    <div class="relative max-w-lg-secondary mx-auto px-8 py-10 flex flex-col gap-6 xl:gap-8 xl:py-20 xl:pl-10">
      <h2 class="text-xl text-white normal-case md:text-2xl lg:text-3xl lg:w-3/4 xl:w-7/12" v-html="transifex.newsletter.title"></h2>
      <div class="flex flex-col gap-8 lg:items-center lg:flex-row">
        <p class="text-xs text-white font-bold md:text-xl lg:text-2xl" v-html="transifex.newsletter.description"></p>
        <div class="flex flex-col">
          <form v-on:submit.prevent="onSubmit" class="flex">
            <input type="email" :placeholder="transifex.newsletter.placeholder" v-model="newsletterEmail" required class="text-xs font-medium rounded-l-3xl placeholder:italic placeholder:text-slate-400 pl-6 pr-4 py-3 w-52 md:w-[430px] md:text-2xl">
            <button type="submit" class="btn-primary rounded-l-none rounded-r-3xl whitespace-nowrap px-8 md:px-12" v-html="transifex.newsletter.btn"></button>
          </form>
          <div v-if="successfulMessage" class="ml-4 mt-2 font-bold">{{successfulMessage}}</div>
          <div v-if="errorMessage" class="ml-4 mt-2 font-bold">{{errorMessage}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formatNewsletterSchema} from "@/js/newsletter_schema";
import axios from "axios";
import {GTMManager} from "@/js/gtm_manager";

export default {
  name: "newsletter-section",
  props: {
    transifex: null,
  },
  data() {
    return {
      newsletterEmail: '',
      successfulMessage: null,
      errorMessage: null,
      gtm: new GTMManager()
    }
  },
  methods: {
    async onSubmit() {
			const NewsletterApi = 'https://api.medici.tv/user/newsletter';
			let postData = formatNewsletterSchema(this.newsletterEmail, true);
      try {
        await axios.post(NewsletterApi, postData);
        localStorage.setItem("CliburnMedicitvNewsLetter", "True");
        this.successfulMessage = 'You\'ve successfully subscribed to our newsletter!'
        this.gtm.pushGTM('newsletter_success')
      } catch (error) {
        this.errorMessage = "Unexpected error occurred, Please try again later !"
        this.gtm.pushGTM('newsletter_error')
        console.error(error)
      }
		}
  }
}
</script>

<style lang="scss">
</style>