<template>
  <div v-if="performance" class="pt-5 md:pt-8">
    <div class="myContainer">
      <div class="grid gap-6 md:grid-cols-11">
        <div class="md:col-span-7">
          <PlayerReplay
            :performance="performance"
            :transifex="transifex"
          />
        </div>
        <div class="md:col-span-4 myScrollbar relative h-64 min-h-full flex-1 overflow-auto bg-black px-6 py-5 md:h-full">
          <PlayerReplayChapter
            :performance="performance"
            :transifex="transifex"
          />
        </div>
      </div>
      <!-- Player and chapter -->

      <div v-if="pollActive" class="grid gap-2 md:gap-6 md:grid-cols-12 content-center px-6 py-5 mt-4 md:mt-8 bg-vote-performance-page">
        <div class="col-span-5 uppercase text-xl font-bold text-black flex items-center"><span v-html="transifex.vote.audiencePrize"></span></div>
        <div class="col-span-4 text-base font-bold text-black flex items-center"><span v-html="transifex.vote.chooseFinalist"></span></div>
        <div class="col-span-3 mt-4 sm:mt-0 flex items-center"><a href="/vote" target="_blank" class="px-6 sm:px-14 md:px-8 lg:px-14 text-base py-3 btn-secondary">{{transifex.vote.voteNow}}</a></div>
      </div>

      <ShareOn
          :share-title="performance.title"
          :transifex="transifex"
      />
    </div>

    <div v-if="performance.candidate" class="relative overflow-hidden bg-[#F6F6F6] mt-7 md:mt-14 animate__animated animate__fadeIn">
      <div class="absolute z-0 right-0 top-0 bottom-0">
        <div class="relative h-full">
          <div class="absolute inset-0 bg-gradient-to-t from-[#F6F6F6] sm:bg-gradient-to-r"></div>
          <img src="@/assets/details-background.avif" class="object-cover h-full" alt="details-background.avif">
        </div>
      </div>
      <div class="myContainer relative z-10 pt-10 pb-10 mb:pb-16 lg:pt-14 lg:pb-28">
        <div class="flex items-center pb-2">
          <img src="@/assets/details.svg" class="w-10 h-10 flex-shrink-0 mr-3.5" alt="details.svg">
          <h2>{{performance.title}}</h2>
        </div>

        <div v-if="performance.candidate" class="flex flex-col pt-7 gap-4 sm:flex-row md:gap-8 md:pt-14">
          <img :src="performance.candidate.image" alt="candidate.image" class="object-cover w-44 h-44 md:w-64 md:h-64">
          <div class="text-black">
            <p class="pb-3 leading-4 text-xs font-bold md:text-base lg:leading-7 lg:text-2xl">{{performance.candidate.title}}</p>
            <p class="font-bold leading-5">{{performance.candidate.age}}</p>
            <div>
              <p v-for="(item, index) in performance.candidate.countries" :key="index" class="leading-5 inline-block">
                <span v-if="index > 0">, </span>
                {{item.country.name}}
              </p>
            </div>
            <a :href="performance.candidate.url" class="btn-secondary inline-block mt-7 md:mt-14">{{transifex.performance.viewAll}}</a>
          </div>
        </div> <!-- Candidate -->
      </div>
    </div>
  </div>

<!--  <pre>{{performance}}</pre>-->
</template>

<script>
import axios from "axios";
import PlayerReplay from "@/components/player/PlayerReplay";
import PlayerReplayChapter from "@/components/player/PlayerReplayChapter";
import ShareOn from "@/components/ShareOn";

export default {
  name: "performance-page",
  props: {
    apiRoute: null,
    transifex: null,
    pollActive: {
      type: String,
      default: null
    },
  },
  components: {
    PlayerReplay,
    PlayerReplayChapter,
    ShareOn,
},
  data() {
    return {
      performance: null,
    }
  },
  async beforeCreate() {
    const {data} = await axios.get(this.apiRoute);
    if (data.performance?.id) {
      this.performance = data.performance
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.bg-vote-performance-page {
  background: #f5f5f5;
}
</style>