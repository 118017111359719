<template>
  <div>
    <div class="myContainer text-black">
      <div class="grid grid-cols-2 gap-6 pt-7 md:pt-11 md:grid-cols-3 md:gap-12">
        <div v-for="(item, index) in computedJuryItems" :key="index">
          <a :href="item.url" class="group relative inline-block w-full">
            <img :src="getImage(item)" class="object-cover w-full h-44 md:h-60 xl:h-88" :alt="item.title">
            <div class="absolute inset-0 opacity-0 flex items-center justify-center transition-all ease-in-out group-hover:opacity-100" :style="{backgroundColor: 'rgba(17, 110, 150, .7)'}">
              <p class="text-base text-white font-bold lg:text-[22px]">{{transifex.common.seeMore}}</p>
            </div>
          </a>

          <h2 class="text-sm md:text-base lg:text-2xl normal-case">{{item.title}}</h2>
          <p v-if="item.age">{{transifex.common.age}} {{item.age}}</p>
          <div v-if="item.countries.length > 0">
            <p v-for="(itemC, index) in item.countries" :key="index" class="leading-5 inline-block">
              {{itemC.country.name}}<span v-if="index >= 0 && index != item.countries.length - 1">,&nbsp;</span>
            </p>
          </div>
          
        </div>
      </div>
      <div v-if="isShow && limitationList < juryItems.length" class="flex justify-center pt-16">
        <button @click="showButton" class="btn-secondary">{{transifex.common.seeMore}}</button>
      </div>
    </div>
  </div>
<!--  <pre>{{candidateItems}}</pre>-->
</template>

<script>
import axios from "axios";

export default {
  name: "jury-index",
  props: {
    apiRoute: null,
    transifex: null
  },
  data() {
    return {
      juryItems: [],
      limitationList: 12,
      isShow: true
    }
  },
  computed:{
    computedJuryItems(){
      return this.limitationList ? this.juryItems.slice(0,this.limitationList) : this.juryItems
    },
  },
  async beforeCreate() {
    const {data} = await axios.get(this.apiRoute);
    if (data.count > 0) {
      this.juryItems = data.results
    }
  },
  mounted() {
  },
  methods: {
    showButton(e) {
      if (e && this.limitationList < this.juryItems.length) {
        this.isShow = false
        this.limitationList = null
      }
    },
    getImage(item) {
      if (document.body.clientWidth < 525) {
        return item.image
      }
      return item.image_16_9
    }
  }
}
</script>