<template>
<div class="popover__wrapper w-full">
  <a :href="'#'+popoverID" class="w-full">
    <p class="text-primary underline">Program</p>
  </a>
  <div class="popover__content scrollbar-popover" id="style-4">
    <div v-for="(workMovement, index) in worksMovements" :key="index" class="px-1 md:px-2 pt-2">
      <p v-if="workMovement.artists" class="text-primary">{{workMovement.artists}}</p>
      <p v-if="workMovement.musical_work">
        <a v-if="workMovement.musical_work.timecode" :href="performance.url + '#timecode=' + workMovement.musical_work.timecode"
           class="cursor-pointer text-under-line-blue">{{workMovement.musical_work.title}}</a>
        <span v-else >{{workMovement.musical_work.title}}</span>
      </p>
      <ul>
        <li v-for=" (movement, index) in workMovement.musical_work_movement" :key="index"
            class="px-3 md:px-5 text-xs md:text-sm">
          <a v-if="movement.timecode" :href="performance.url + '#timecode=' + movement.timecode"
             class="cursor-pointer text-under-line-blue">{{movement.title}}</a>
          <span v-else>{{movement.title}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: "program-popover",
  components: {
  },
  props: {
    worksMovements: null,
    popoverID: null,
    performance: null
  },
}
</script>

<style lang="scss">
a {
  text-decoration: none;
}

.popover__wrapper {
  position: relative;
  display: inline-block;
}
.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translate(0, 10px);
  background-color: #fdfcfc;
  padding: 0.5rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: 240px;
  height: 260px;
  left: 75px;
  top: -132px;
  @media only screen and (max-width: 1280px) {
    top: -86px;
    left: 60px;
  }
  @media only screen and (max-width: 768px) {
    top: -135px;
    left: 60px;
    width: 220px;
  }
  @media only screen and (max-width: 425px) {
    top: -90px;
    left: 50px;
    width: 195px;
    height: 230px;
    padding: 0.2rem;
  }
  @media only screen and (max-width: 375px) {
    left: 30px;
    width: 170px;
    height: 230px;
    padding: 0.1rem;
  }
  @media only screen and (max-width: 340px) {
    left: 20px;
    width: 150px;
    height: 230px;
    padding: 0.1rem;
  }
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
  text-align: center;
}

.scrollbar-popover {
	float: left;
	overflow-y: scroll;
}
#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

#style-4::-webkit-scrollbar
{
	width: 5px;
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: #c2c0c0;
	border: 2px solid #c2c0c0;
}
.text-under-line-blue {
	text-docoration: none;
}

/* show underline on hover */
.text-under-line-blue:hover {
	text-decoration: underline;
  text-underline-offset: 3px;
	text-decoration-color: #31AEE4;
	-webkit-text-decoration-color: #31AEE4;
}
</style>