<template>
  <div v-if="news" class="latest-news text-black pt-7 md:pt-10">
    <div
      class="myContainer flex justify-between items-baseline pb-3 lg:pb-8"
    >
      <h2>Latest <span class="text-primary">News</span></h2>
      <a
        :href="'/news/'"
        class="hidden text-black font-bold text-xl hover:text-primary sm:inline-block"
        >Show all</a
      >
    </div>
    <swiper
      :navigation="true" 
      :modules="modules" 
      slides-per-view="auto"
      :space-between="35"
      :breakpoints="swiperOptions.breakpoints"
      class="small-slide"
    >
      <swiper-slide v-for="(item, index) in news" :key="index">
        <a :href="'/news/' + item.slug" class="group relative inline-block w-full">
          <img :src="item.image" :alt="item.title" class="object-cover w-full h-40 md:h-52 xl:h-64"/>
          <div class="absolute inset-0 opacity-0 flex items-center justify-center transition-all ease-in-out group-hover:opacity-100"
               style="background-color: rgba(17, 110, 150, .7);">
            <p class="text-base text-white font-bold lg:text-[22px]">{{transifex.common.seeMore}}</p>
          </div>
        </a>
        <div class="pt-2">
          <h3>{{item.title}}</h3>
          <p v-html="item.description" class="line-clamp-3"></p>
          <div class="mt-1 text-xs font-semibold text-slate-500 lg:text-sm">{{item.posting_date}}</div>
          <div class="my-3 flex gap-2">
            <span v-for="(itemY, indexY) in item.categories" :key="indexY">
              <a :href="itemY.category.slug" class="tag px-2 py-1 lg:text-base">#{{itemY.category.title}}</a>
            </span>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import "swiper/css";

export default {
  name: "latest-news",
  props: {
    apiRoute: null,
    transifex: null
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  data() {
    return {
      news: null,
      swiperOptions: {
        breakpoints: {       
          320: {       
            slidesPerView: 'auto',
            spaceBetween: 10     
          },          
          520: {       
            slidesPerView: 2,       
            spaceBetween: 20     
          },
          1024: {       
            slidesPerView: 3,       
            spaceBetween: 35     
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 35
          },
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
  },
  async beforeCreate() {
    const {data} = await axios.get(this.apiRoute);
    if (data.count > 0) {
      this.news = data.results
      this.news.map(el => {
        el.posting_date = moment(el.date).format('lll');
      })
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
//.swiper:deep() {
//
//  .swiper-slide {
//    width: 80%;
//
//    @media only screen and (min-width: 520px) {
//      width: 40% !important;
//      max-width: 400px;
//    }
//
//    @media only screen and (min-width: 1024px) {
//      width: 30% !important;
//    }
//  }
//}
</style>