<template>
  <div v-if="videoUrl">
    <div :id="id"></div>
  </div>
</template>

<script>

export default {
  name: "player-replay",
  components:  {

  },
  props: {
    videoUrl: null
  },
  data() {
    return {
      id: "player-brahms"
    }
  },
  computed: {
  },
  mounted() {
    if (this.videoUrl) {
      this.$store.commit('set_player', this.id)
      const additionalSettings = {
        playlist: [{file: this.videoUrl}],
      }
      let movieGtmData = this.getGtmData()
      let movieAudienceData = this.getAudienceData()
      this.$store.commit('start_player', {additionalSettings, movieGtmData, movieAudienceData})
    }
  },
  methods: {
    getGtmData() {
      return {
        video_id: '88888888',
        video_name: window.location.pathname,
        video_category: 'others',
        video_type: "static",
        video_url: window.location.pathname
      }
    },
    getAudienceData() {
      return {
        id: '88888888',
        slug: window.location.pathname,
        type: "static",
        category: "others",
      }
    }
  }
}
</script>

<style lang="scss">
</style>