<template>
  <div>
    <div class="myContainer text-black">
      <div class="pt-7 md:pt-11">
        <h2 class="text-sm md:text-base lg:text-2xl normal-case">{{transifex.common.filterBy}}</h2>
        <v-select :multiple="true"
                  :placeholder="transifex.common.country"
                  :modelValue=selectedCountry
                  :options="countries" label="name"
                  :reduce="country => country.code"
                  :filterable="true"
                  @update:modelValue="updateCountryFilter"
                  class="max-w-xs border-none	pt-4"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3">
              <img src="@/assets/vselect-arrow.svg" alt="vselect-arrow.svg" class="w-4 h-4 flex-shrink-0 cursor-auto">
            </span>
          </template>
        </v-select>
      </div>

      <div class="grid grid-cols-2 gap-6 pt-7 md:pt-11 md:grid-cols-3 md:gap-12">
        <div v-for="(item, index) in computedCandidateItems" :key="index">
            <a :href="item.url" class="group relative inline-block w-full">
              <img :src="getImage(item)" class="object-cover w-full h-44 md:h-60 xl:h-88" :alt="item.title">
              <div class="absolute inset-0 opacity-0 flex items-center justify-center transition-all ease-in-out group-hover:opacity-100" :style="{backgroundColor: 'rgba(17, 110, 150, .7)'}">
                <p class="text-base text-white font-bold lg:text-[22px]">{{transifex.common.seeMore}}</p>
              </div>
            </a>

            <h2 class="text-sm md:text-base lg:text-2xl normal-case">{{item.title}}</h2>
            <p v-if="item.age">Age: {{item.age}}</p>
            <div v-if="item.countries.length > 0">
              <p v-for="(itemC, index) in item.countries" :key="index" class="leading-5 inline-block">
                {{itemC.country.name}}<span v-if="index >= 0 && index != item.countries.length - 1">,&nbsp;</span>
              </p>
            </div>
          </div>
      </div>
      <div v-if="isShow && limitationList < candidateItems.length" class="flex justify-center pt-16">
        <button @click="showButton" class="btn-secondary">{{transifex.common.seeMore}}</button>
      </div>
    </div>
  </div>
<!--  <pre>{{candidateItems}}</pre>-->
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import {appUtils} from "@/js/utils"

export default {
  name: "candidate-index",
  components: {
    vSelect,},
  props: {
    apiRoute: null,
    apiFilterRoute: null,
    transifex: null
  },
  data() {
    return {
      candidateItems: [],
      countries: [],
      selectedCountry: [],
      limitationList: 40,
      isShow: true
    }
  },
  computed:{
    computedCandidateItems(){
      return this.limitationList ? this.candidateItems.slice(0,this.limitationList) : this.candidateItems
    },
  },
  async beforeCreate() {
    const result = await axios.get(this.apiFilterRoute);
    if (result.data?.country) {
      this.countries = result.data.country
    }

    let searchParams = new URLSearchParams(window.location.search);
    let countryParams = searchParams.get('country')
    if (countryParams)
      this.selectedCountry = countryParams.split(',')

    let searchAPIRoute = this.apiRoute + '&' + searchParams.toString();
    const {data} = await axios.get(searchAPIRoute);
    if (data.count > 0) {
      this.candidateItems = data.results
    }
  },
  mounted() {
  },
  methods: {
    async updateCountryFilter(value) {
      this.selectedCountry = value
      await this.updateFilter(value, 'country')
    },
    async updateFilter(selectedFilter, queryParam) {
      appUtils.updateFilter(selectedFilter, queryParam)
      let searchParams = new URLSearchParams(window.location.search);
      let searchAPIRoute = this.apiRoute + '&' + searchParams.toString();
      const {data} = await axios.get(searchAPIRoute);
      this.candidateItems = data.results
    },
    showButton(e) {
      if (e && this.limitationList < this.candidateItems.length) {
        this.isShow = false
        this.limitationList = null
      }
    },
    getImage(item) {
      if (document.body.clientWidth < 525) {
        return item.image
      }
      return item.image_16_9
    }
  }
}
</script>