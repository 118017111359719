import {PauseAbleTimer} from "./utils";
class GTMManager {
  constructor(app = {}) {
    this.baseGtmVars = {}
    this.app = app

  }

  pushGTM(eventName, setting) {
    if (window.dataLayer) {
      window.dataLayer = window?.dataLayer || []
      if (setting) this.updateGTM(setting)
      this.baseGtmVars.event = eventName // add event name for push gtm
      this.baseGtmVars.event_pushing_time = Date.now() // add pushing time
      window.dataLayer.push(this.baseGtmVars)

      delete this.baseGtmVars.event // remove event name after push for using push in another time
    }
  }

  updateGTM(updateSetting) {
    // TODO: AVOID for in without hasOwnProperty
    for (const stat in updateSetting) {
      this.baseGtmVars[stat] = updateSetting[stat]
    }
  }
}

/** ********************** GtmPlayerCollector ************************/
class GtmPlayerCollector extends GTMManager {
  constructor(playerInstance) {
    super()
    this.playerInstance = playerInstance
    this.gtmCollectedStats = {
      user_id: "",
      user_status: "",

      video_id: "",
      video_name: "",
      video_category: "",
      video_type: "",
      video_quality: "",
      video_play_time: "",
      video_price: ""
    }
    this.timersCollector = []
  }

  startCollect(trackingData) {
    this.gtmCollectedStats = {
      ...this.gtmCollectedStats,
      ...trackingData
    }
    this.gtmCollectedStats.video_quality =
      this.playerInstance.getCurrentQuality()
    this.gtmCollectedStats.video_play_time = this.playerInstance.getDuration()

    const player_timer_1s = new PauseAbleTimer(() => {
      this.triggerPushGTM("jw_player_movie_0_sec")
      player_timer_1s.clearTimer()
    }, 1000) // GA-Event play_movie_0 sec

    const player_timer_30s = new PauseAbleTimer(() => {
      this.triggerPushGTM("jw_player_movie_30_sec")
      player_timer_30s.clearTimer()
    }, 30000) // GA-Event play_movie

    const player_timer_180s = new PauseAbleTimer(() => {
      this.triggerPushGTM("jw_player_movie_3_min")
      player_timer_180s.clearTimer()
    }, 180000) // GA-Event-Roy

    this.timersCollector = [
      player_timer_1s,
      player_timer_30s,
      player_timer_180s
    ]
    this.timersCollector.forEach(function (timer) {
      timer.startTimer()
    })
  }

  triggerGtmSACEM() {
    this.gtmCollectedStats.video_quality =
      this.playerInstance.getCurrentQuality()
    this.triggerPushGTM("jw_player_movie_sacem")
  }

  triggerPushGTM(code) {
    this.gtmCollectedStats.player_position = parseInt(
      this.playerInstance.getPosition()
    )
    this.pushGTM(code, this.gtmCollectedStats)
  }
}

export { GTMManager, GtmPlayerCollector }
