<template>
    <form v-on:submit.prevent="onSubmit" class="myScrollbar overflow-auto px-6 py-4 md:px-12 md:py-8">
        <h2 class="text-white normal-case text-sm md:text-base lg:text-xl lg:leading-7">{{transifex.newsletterPlayer.email}}</h2>
        <input id="newsletterEmailMedici" v-model="newsletterEmail" type="email" name="EMAIL" :placeholder="transifex.newsletter.placeholder" aria-label="Email address" aria-describedby="basic-addon2" required class="text-xs font-medium rounded-3xl placeholder:text-slate-400 my-3 px-6 py-3 w-full md:my-6 md:text-lg">

<!--        <input type="checkbox" id="subscriberMedici" name="OPTINDT" value='yes'>-->
<!--        <label for="subscriberMedici" class="ml-2 text-sm md:text-base">{{transifex.newsletterPlayer.subscriberMedici}}</label>-->

      <p class="my-1 leading-5">{{transifex.newsletterPlayer.policy}}</p>

      <div class="flex flex-col items-end gap-3">
        <button type="submit" class="btn-primary text-sm md:text-base px-6 py-2">
          {{transifex.newsletterPlayer.btn}}
        </button>

        <span id="MaybeLaterNewsletter" class="newsletter-maybe-later text-sm md:text-base" @click="onMaybeLater()">
          {{transifex.newsletterPlayer.later}}
        </span>
      </div>
    </form>
</template>

<script>

import {JwplayerViewManager} from "@/js/medici_player";
import {formatNewsletterSchema} from "@/js/newsletter_schema";
import axios from "axios";
import {GTMManager} from "@/js/gtm_manager";

export default {
  name: "newsletter-player",
  components:  {

  },
  props: {
    transifex: null
  },
  data() {
    return {
      newsletterEmail: null,
      jwplayerView: new JwplayerViewManager(),
      gtm: new GTMManager()
    }
  },
  computed: {
  },
  mounted() {

  },
  methods: {
    onSubmit() {
      this.jwplayerView.hideNewsletterOverlaySubmit(this.$store.state.playerInstance)
      this.signupEmail()
    },
    onMaybeLater() {
      this.jwplayerView.hideNewsletterOverlay(this.$store.state.playerInstance)
    },
		async signupEmail() {
			// const isMediciNewsletter  = document.getElementById('subscriberMedici')
			// 	? document.getElementById('subscriberMedici').checked : false;
			const NewsletterApi = 'https://api.medici.tv/user/newsletter';
			let postData = formatNewsletterSchema(this.newsletterEmail, true);
      try {
        await axios.post(NewsletterApi, postData);
        this.gtm.pushGTM('newsletter_success')
      } catch (error) {
        console.error(error)
        this.gtm.pushGTM('newsletter_error')
      }
		}
  }
}
</script>

<style lang="scss">
.newsletter-maybe-later {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.newsletter-maybe-later:hover {
  -webkit-text-stroke: 0.3px white;
}
</style>