<template>
  <a v-if="item.type==='p' && item.performance" :href="item.performance.url" class="group inline-block relative w-full z-0">
    <img :src="item.performance.image" :alt="item.performance.title" class="object-cover w-full h-52 md:h-64 xl:h-96">
    <img src="@/assets/player.svg" class="opacity-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 duration-200 ease-in-out w-10 md:w-16 xl:w-28 group-hover:opacity-100" alt="player.svg">
    <div class="absolute z-10 left-0 right-0 bottom-0 px-4 py-3" :style="{backgroundColor: 'rgba(18, 18, 18, 0.6)'}">
      <h3 class="text-white">{{item.performance.title}}</h3>
      <p v-if="item.performance.candidate" class="text-xs md:text-sm lg:text-base">
        <span v-if="item.round.title">{{item.round.title}}</span>
        <span v-if="item.performance.candidate.title && item.round.title"> • </span>
        <span v-if="item.performance.candidate.title">{{item.performance.candidate.title}}</span>
      </p>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    item: {},
  }
}
</script>