<template>
  <div>
    <div class="myContainer text-black">
      <div class="pt-7 md:pb-4 md:pt-10">
        <p class="font-bold">{{transifex.common.filterBy}}</p>
        <div class="grid gap-4 pt-3 sm:grid-cols-2 md:grid-cols-4">

        <v-select :multiple="true"
                  :placeholder="transifex.common.candidate"
                  :modelValue=selectedCandidate
                  :options="candidates" label="title"
                  :reduce="candidate => candidate.id"
                  :filterable="true"
                  @update:modelValue="updateCandidateFilter"
                  class="sm:col-span-2 md:col-span-1"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3">
              <img src="@/assets/vselect-arrow.svg" class="w-4 h-4 flex-shrink-0 cursor-auto" alt="vselect-arrow.svg">
            </span>
          </template>
        </v-select>

          <v-select :multiple="true"
                    :placeholder="transifex.common.country"
                    :modelValue=selectedCountry
                    :options="countries" label="name"
                    :reduce="country => country.code"
                    :filterable="true"
                    @update:modelValue="updateCountryFilter"
                    class="sm:col-span-2 md:col-span-1"
          >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3">
              <img src="@/assets/vselect-arrow.svg" class="w-4 h-4 flex-shrink-0 cursor-auto" alt="vselect-arrow.svg">
            </span>
          </template>
        </v-select>
        <v-select :multiple="true"
                  :placeholder="transifex.common.round"
                  :modelValue=selectedRound
                  :options="rounds" label="title"
                  :reduce="round => round.id"
                  :filterable="true"
                  @update:modelValue="updateRoundFilter"
                  class="sm:col-span-2 md:col-span-1"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3">
              <img src="@/assets/vselect-arrow.svg" class="w-4 h-4 flex-shrink-0 cursor-auto" alt="vselect-arrow.svg">
            </span>
          </template>
        </v-select>

        <v-select v-if="!replayPage"
                  :multiple="true"
                  :placeholder="transifex.common.datetime"
                  :modelValue=selectedDateTime
                  :options="dateTimes" label="title"
                  :reduce="datetime => datetime.id"
                  :filterable="true"
                  @update:modelValue="updateDateTimeFilter"
                  class="sm:col-span-2 md:col-span-1"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3">
              <img src="@/assets/vselect-arrow.svg" class="w-4 h-4 flex-shrink-0 cursor-auto" alt="vselect-arrow.svg">
            </span>
          </template>
        </v-select>

        <v-select v-if="replayPage"
                  :multiple="true"
                  :placeholder="'Musical work'"
                  :modelValue=selectedMusicalWork
                  :options="musicalWork" label="title"
                  :reduce="work => work.id"
                  :filterable="true"
                  @update:modelValue="updateMusicalWorkFilter"
                  class="sm:col-span-2 md:col-span-1"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3">
              <img src="@/assets/vselect-arrow.svg" class="w-4 h-4 flex-shrink-0 cursor-auto" alt="vselect-arrow.svg">
            </span>
          </template>
        </v-select>

        </div>
      </div>

      <div v-for="(item, index) in calendarItems" :key="index">
        <div v-if="item.type==='r'" 
            :class="`grid gap-4 items-center py-7 lg:gap-8 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-12 ${ index > 0 ? 'border-t-2 border-gray-300' : ''}`">
          <div class="col-span-3 lg:col-span-1 xl:col-span-2">
            <p v-if="item.editor_text" class="text-xs font-bold md:text-base lg:text-2xl">
              {{item.editor_text.title}}
            </p>
            <p v-else class="text-xs font-bold md:text-base lg:text-2xl">
              {{item.round.title}}
            </p>
          </div>
          <div class="col-span-3 sm:col-span-2 xl:col-span-4 flex items-center">
            <img :src="item.round.image" height="80" :alt="item.round.title" class="w-32 h-32">
          </div>
          <div class="col-span-2 sm:col-span-1 xl:col-span-3 text-xs font-bold md:text-base lg:text-2xl">
            <p class="leading-4 text-xs font-bold md:text-base md:pb-3 lg:leading-7 lg:text-2xl">{{item.formatted_date}}</p>
            <p class="leading-4 text-xs font-bold md:text-base md:pb-3 lg:leading-7 lg:text-2xl">{{item.formatted_time}}</p>
            <p class="selected-time cursor-pointer" @click="showTimeZoneModal()">{{transifex.common.timezone}} {{selectedTimeZone}}</p>
          </div>
          <div class="col-span-3 justify-self-start lg:col-span-4 xl:col-span-3">
            <div title="Add to Calendar" class="addeventatc z-index-5">
              {{transifex.schedule.addToCalendar}}
              <span class="start">{{item.formatted_addEvent}}</span>
              <span class="timezone">{{selectedTimeZone}}</span>
              <span class="title">{{item.round.title}}, The Cliburn International Piano Competition</span>
              <span class="description">{{item.round.title}}, The Van Cliburn International Piano Competition will be held June 2–18, 2022. Explore one of the world's most prestigious piano competitions.</span>
              <span class="location">FORT WORTH, TEXAS USA</span>
              <span class="client">ayQWDRnHdztJOVqDymZh130079</span>
            </div>
          </div>
        </div>

        <div v-if="item.type==='p' && item.performance"
            :class="`grid gap-4 items-center py-7 lg:gap-8 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-12 ${ index > 0 ? 'border-t-2 border-gray-300' : ''}`"
        >
          <div class="col-span-3 lg:col-span-1 xl:col-span-2">
            <!-- Hot fix at these end of cliburn need to remove in next competition -->
            <p class="text-xs font-bold md:text-base lg:text-2xl">
              <span v-if="item.round.title==='Semifinals'">
                <span v-if="item.editor_text">{{item.editor_text.title}}</span>
                <span v-else>{{item.round.title}}</span>
              </span>
              <span v-else>
                {{item.round.title}}
              </span>
            </p>

          </div>
          <div v-if="item.performance" class="col-span-4 sm:col-span-2 xl:col-span-4 flex items-center">
            <img v-if="item.performance.candidate" :src="item.performance.candidate.image" height="80" :alt="item.performance.candidate.title" class="w-32 h-32">
            <img v-else :src="item.performance.image" height="80" :alt="item.performance.title" class="w-32 h-32"> <!-- performance without candidate -->

            <div class="px-4 md:px-8">
              <p v-if="item.performance.candidate" class="leading-4 text-xs font-bold md:text-base lg:leading-7 lg:text-2xl xl:pb-3">{{item.performance.candidate.title}}</p>
              <p v-else class="leading-4 text-xs font-bold md:text-base lg:leading-7 lg:text-2xl xl:pb-3">{{item.performance.title}}</p>

              <p v-if="item.performance.candidate && item.performance.candidate.age" class="font-bold leading-5">{{item.performance.candidate.age}} {{transifex.common.yearsOld}}</p>
              <div v-if="item.performance.candidate">
                <p v-for="(itemC, index) in item.performance.candidate.countries" :key="index" class="leading-5 inline-block">
                  {{itemC.country.name}}<span v-if="index >= 0 && index != item.performance.candidate.countries.length - 1">,&nbsp;</span>
                </p>
              </div>
              <ProgramPopover
                  v-if="item.performance.works_movements"
                  :worksMovements="item.performance.works_movements"
                  :popoverID="item.performance.slug"
                  :performance="item.performance"
              />
            </div>
          </div>
          <div class="col-span-2 sm:col-span-1 xl:col-span-3 text-xs font-bold md:text-base lg:text-2xl">
            <p class="leading-4 text-xs font-bold md:text-base md:pb-3 lg:leading-7 lg:text-2xl">{{item.formatted_date}}</p>
            <p class="leading-4 text-xs font-bold md:text-base md:pb-3 lg:leading-7 lg:text-2xl">{{item.formatted_time}}</p>
            <p class="selected-time cursor-pointer" @click="showTimeZoneModal()">{{transifex.common.timezone}} {{selectedTimeZone}}</p>
          </div>
          <div class="col-span-3 justify-self-start lg:col-span-4 xl:col-span-3">
            <a v-if="item.status==='rd'" :href="item.performance.url" class="btn-secondary flex items-center whitespace-nowrap py-2.5">
              <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-4">
                <path d="M2 2V23L19 12.5L2 2Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>{{transifex.schedule.watchReplay}}</span>
            </a>
            <a v-if="item.status==='nr'" :href="item.performance.url" type="button" class="link">{{transifex.schedule.comingSoon}}</a>
            <div v-if="item.status==='ad'" title="Add to Calendar" class="addeventatc z-index-5">
              {{transifex.schedule.addToCalendar}}
              <span class="start">{{item.formatted_addEvent}}</span>
              <span class="timezone">{{selectedTimeZone}}</span>
              <span class="title">{{item.performance.title}}, The Cliburn International Piano Competition</span>
              <span class="description">{{item.performance.title}}, The Van Cliburn International Piano Competition will be held June 2–18, 2022. Explore one of the world's most prestigious piano competitions.</span>
              <span class="location">FORT WORTH, TEXAS USA</span>
              <span class="client">ayQWDRnHdztJOVqDymZh130079</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="nextOffset" class="flex justify-center pt-16">
        <button @click="showMore" class="btn-secondary">{{transifex.common.seeMore}}</button>
      </div>

      <p v-if="calendarItems.length === 0" class="mt-8 text-center">{{transifex.common.noResultsFound}}</p>
    </div>
    <TimeZoneModal
        @changeTimeZone="changeTimeZone"
        :transifex="transifex"
    ></TimeZoneModal>
  </div>
<!--    <pre>{{countries}}</pre>-->
<!--    <pre>{{rounds}}</pre>-->
<!--    <pre>{{calendarItems}}</pre>-->
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import moment from 'moment';
import timezone from 'moment-timezone';
import {appUtils} from "@/js/utils"
import TimeZoneModal from "@/components/TimeZoneModal";
import ProgramPopover from "@/components/ProgramPopover";
export default {
  name: "schedule-page",
  components: {
    vSelect,
    TimeZoneModal,
    ProgramPopover
  },
  data() {
    return {
      calendarItems: [],
      countries: [],
      rounds: [],
      candidates: [],
      dateTimes: [],
      musicalWork: [],
      selectedCountry: [],
      selectedRound: [],
      selectedCandidate: [],
      selectedDateTime: [],
      selectedMusicalWork: [],
      selectedTimeZone: null,
      nextOffset: null
    }
  },
  props: {
    apiRoute: null,
    apiFilterRoute: null,
    replayPage: null,
    transifex: null
  },
  computed: {
  },
  async beforeCreate() {
    let apiFilter = this.apiFilterRoute
    if(this.replayPage){
      apiFilter = apiFilter + '?' + 'replay_page=true'
    }
    const result = await axios.get(apiFilter);
    if (result.data?.country) {
      this.countries = result.data.country
      this.rounds = result.data.round
      this.candidates = result.data.candidate
      this.musicalWork = result.data.musical_work
      this.dateTimes = result.data.date_time
      this.dateTimes.map( el => {
        el.title = moment(el.title).format('LL')
      })
    }

    let searchParams = new URLSearchParams(window.location.search);
    let countryParams = searchParams.get('country')
    let roundParams = searchParams.get('round')
    let candidateParams = searchParams.get('candidate')
    let datetimeParams = searchParams.get('datetime')
    let musicalWorkParams = searchParams.get('musical_work')
    if (countryParams)
      this.selectedCountry = countryParams.split(',')
    if (roundParams) {
      roundParams = roundParams.split(',')
      roundParams = roundParams.map( el => parseInt(el, 10))
      this.selectedRound = roundParams
    }
    if (candidateParams) {
      candidateParams = candidateParams.split(',')
      candidateParams = candidateParams.map( el => parseInt(el, 10))
      this.selectedCandidate = candidateParams
    }
    if (musicalWorkParams) {
      musicalWorkParams = musicalWorkParams.split(',')
      musicalWorkParams = musicalWorkParams.map( el => parseInt(el, 10))
      this.selectedMusicalWork = musicalWorkParams
    }
    if (datetimeParams) {
      this.selectedDateTime = datetimeParams.split(',')
    }
    if(this.replayPage){
      searchParams.append('replay_page', 'true')
    }

    let searchAPIRoute = this.apiRoute + '&' + searchParams.toString();
    await this.updateAPI(searchAPIRoute);
  },
  methods: {
    async updateCountryFilter(value) {
      this.selectedCountry = value
      await this.updateFilter(value, 'country')
    },
    async updateRoundFilter(value) {
      this.selectedRound = value
      await this.updateFilter(value, 'round')
    },
    async updateCandidateFilter(value) {
      this.selectedCandidate = value
      await this.updateFilter(value, 'candidate')
    },
    async updateDateTimeFilter(value) {
      this.selectedDateTime = value
      await this.updateFilter(value, 'datetime')
    },
    async updateMusicalWorkFilter(value) {
      this.selectedMusicalWork = value
      await this.updateFilter(value, 'musical_work')
    },
    async updateFilter(selectedFilter, queryParam) {
      appUtils.updateFilter(selectedFilter, queryParam)
      let searchParams = new URLSearchParams(window.location.search);
      if(this.replayPage){
        searchParams.append('replay_page', 'true')
      }
      let searchAPIRoute = this.apiRoute + '&' + searchParams.toString();
      await this.updateAPI(searchAPIRoute)
    },
    async showMore() {
      await this.updateAPI(this.nextOffset, true)
      this.loadJS()  // reload script to force addevent re-render btn add to calendar
    },
    showTimeZoneModal() {
      let modal = document.getElementById("modalTimeZone");
      modal.style.display = "block"
    },
    changeTimeZone(value) {
      this.selectedTimeZone = value
      this.calendarItems.map( el => {
        el.formatted_date = timezone.tz(el.start_date, value).format('LL');
        el.formatted_time = timezone.tz(el.start_date, value).format('LT');
        el.formatted_addEvent = timezone.tz(el.start_date).format('L LT');
      })
    },
    async updateAPI(searchAPIRoute, nextOffset=false) {
      const {data} = await axios.get(searchAPIRoute);
      if (data.count > 0) {
        if(nextOffset)
          this.calendarItems = this.calendarItems.concat(data.results)
        else
          this.calendarItems = data.results
        // Drf return absolut next url in http, then in prod https can't response in a request http
        // So we need to use split to get relative url
        this.nextOffset = data.next ? data.next.split(location.host.replace('www.',''))[1] : null
        this.calendarItems.map(el => {
          el.formatted_date = moment(el.start_date).format('LL');
          el.formatted_time = moment(el.start_date).format('LT');
          el.formatted_addEvent = moment(el.start_date).format('L LT');
        })
        this.selectedTimeZone = timezone.tz.guess()
      }
    },
    loadJS()
   {
      let head= document.getElementsByTagName('head')[0];
      let script= document.createElement('script');
      script.src= 'https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js';
      head.appendChild(script);
   }
  }
}
</script>

<style lang="scss">
.selected-time:hover {
  text-decoration: underline;
  color: #007aff;
}
.z-index-5 {
  z-index: 5 !important;
}
</style>