<template>
  <div class="px-6 grid grid-cols-2 py-2">
    <h3 class="text-primary cursor-pointer justify-self-start" @click="openProgramTab('live-chapter')">{{transifex.playerChapter.program}}</h3>
    <h3 v-if="currentLive.live_chat" class="text-primary cursor-pointer justify-self-end" @click="openProgramTab('live-chat')">Live Chat</h3>
  </div>
  <div class="relative myScrollbar overflow-auto h-chapter-live">
  <div class="absolute inset-0">
    <div id="live-chapter" class="program-tab">
      <div v-if="currentLive.live_moment" class="px-6">
        <h3 class="px-3 text-primary lg:text-base">{{transifex.playerChapter.liveMoment}}</h3>
        <p class="px-6 pt-1">{{currentLive.live_moment}}</p>
      </div>
      <div v-else class="px-6">
        <div v-if="currentPerformance" class="pb-2 px-2">
          <h3 class="text-primary lg:text-base">{{transifex.playerChapter.currentPerformance}}</h3>
          <p v-if="currentPerformance.title" class="px-3">{{currentPerformance.title}}</p>
          <div v-for="(workMovement, index) in currentPerformance.works_movements" :key="index" class="px-3 pt-2">
            <p v-if="workMovement.artists" class="text-primary">{{workMovement.artists}}</p>
            <p v-if="workMovement.musical_work">{{workMovement.musical_work.title}}</p>
            <ul>
              <li v-for=" (movement, index) in workMovement.musical_work_movement" :key="index" class="px-7 text-xs md:text-sm">
                {{movement.title}}
              </li>
            </ul>
          </div>
        </div>
        <div v-if="nextPerformance" class="pb-4 px-2">
          <h3 class="text-primary lg:text-base">{{transifex.playerChapter.nextPerformance}}</h3>
          <p v-if="nextPerformance.title" class="px-3">{{nextPerformance.title}}</p>
        </div>
      </div>
    </div>

    <PlayerLivechat
        v-if="currentLive && currentLive.live_chat"
        id="live-chat"
        class="program-tab hidden px-2"
        :chatId="currentLive.live_chat"
    />
  </div>
    </div>
</template>

<script>

import PlayerLivechat from "@/components/player/PlayerLivechat";

export default {
  name: "player-live-chapter",
  components: {
    PlayerLivechat
  },
  props: {
    currentLive: null,
    transifex: null
  },
  data() {
    return {
      id: ""
    }
  },
  computed: {
    currentPerformance() {
      if (this.currentLive.performances.length === 0) return null;
      const currentPerformance = this.currentLive.performances.find(el => el.is_current_performance === true);
      if (currentPerformance) return currentPerformance.performance;
      if (this.currentLive.performances.length > 0) return this.currentLive.performances[0].performance;
      return null
    },
    nextPerformance() {
      if (this.currentPerformance) {
        const indexCurrentPerformance = this.currentLive.performances.findIndex(
            el => el.performance.id === this.currentPerformance.id)
        if (indexCurrentPerformance + 1 < this.currentLive.performances.length)
          return this.currentLive.performances[indexCurrentPerformance + 1].performance;
      }
      return null
    }
  },
  methods: {
    openProgramTab(tabName) {
      let i;
      let x = document.getElementsByClassName("program-tab");
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      document.getElementById(tabName).style.display = "block";
    }
  }
}
</script>

<style lang="scss">
.absolute-0 {
  top: 0; bottom: 0; right: 0; left: 0;
}
</style>