import { createApp } from 'vue'
import store from './store'
import Schedule from "@/components/Schedule";
import JuryIndex from "@/components/JuryIndex";
import CandidateIndex from "@/components/CandidateIndex";
import PerformancePage from "@/components/PerformancePage";
import PlayerStatic from "@/components/player/PlayerStatic";
import ShareOn from "@/components/ShareOn";
import NewsletterSection from "@/components/NewsletterSection.vue";
import VotePage from "@/components/VotePage";
import FinalResult from "@/components/FinalResult";
import CarouselHeader from "@/components/CarouselHeader";
import PlayerLiveWrapper from "@/components/player/PlayerLiveWrapper";
import LatestReplays from "@/components/LatestReplays";
import MiniSchedule from "@/components/MiniSchedule";
import MedicitvMovies from "@/components/MedicitvMovies";
import BannerSection from "@/components/BannerSection.vue";
import SupportSection from "@/components/SupportSection.vue";
import PersonalBiography from "@/components/PersonalBiography.vue";
import ResultByRound from "@/components/ResultByRound";
import LatestNews from "@/components/LatestNews";
import PlayerEmbedWrapper from "@/components/player/PlayerEmbedWrapper";
import PlayerEmbedPerformanceWrapper from "@/components/player/PlayerEmbedPerformanceWrapper";
import ProgramPopover from "@/components/ProgramPopover";
import "swiper/css";
import "swiper/css/navigation";
import "./assets/app.css"


const app = createApp({
  components: {
    Schedule,
    JuryIndex,
    CandidateIndex,
    PerformancePage,
    PlayerStatic,
    ShareOn,
    NewsletterSection,
    VotePage,
    FinalResult,
    CarouselHeader,
    PlayerLiveWrapper,
    LatestReplays,
    MiniSchedule,
    MedicitvMovies,
    BannerSection,
    SupportSection,
    PersonalBiography,
    ResultByRound,
    LatestNews,
    PlayerEmbedWrapper,
    PlayerEmbedPerformanceWrapper,
    ProgramPopover
  },
}).use(store)

app.mount('#brahms-app')
