<template>
  <div>
    <div
        :class="{'md:py-12 bg-zinc-900 py-8': currentLive || pollActive}"
    >
      <div class="myContainer">
        <div v-if="currentLive" class="grid gap-6 md:grid-cols-11">
          <div class="md:col-span-7">
            <PlayerLive
                :currentLive="currentLive"
                :transifex="transifex"
            />
          </div>
          <div class="md:col-span-4 relative flex-1 bg-black pb-3">
            <PlayerLiveChapter
                :currentLive="currentLive"
                :transifex="transifex"
            />
          </div>
        </div>

        <div v-if="pollActive"
             class="grid gap-2 md:gap-6 md:grid-cols-12 content-center px-6 py-5 bg-neutral-custom"
             :class="{'mt-4 md:mt-8': currentLive}"
        >
          <div class="col-span-5 uppercase text-xl text-white font-bold flex items-center"><span v-html="transifex.vote.audiencePrize"></span></div>
          <div class="col-span-4 text-base font-bold flex items-center"><span v-html="transifex.vote.chooseFinalist"></span></div>
          <div class="col-span-3 mt-4 sm:mt-0 flex items-center"><a href="/vote" target="_blank" class="px-6 sm:px-14 md:px-8 lg:px-14 text-base py-3 btn-secondary">{{transifex.vote.voteNow}}</a></div>
        </div>

      </div>
    </div>
  </div>
  <LiveInforModal :transifex="transifex" :currentLive="currentLive"></LiveInforModal>
</template>

<script>
import PlayerLive from "@/components/player/PlayerLive";
import PlayerLiveChapter from "@/components/player/PlayerLiveChapter";
import LiveInforModal from "@/components/LiveInforModal";
import { brahmsWebSocket } from "@/js/brahms_websocket"
import axios from "axios";

export default {
  name: "player-live-wrapper",
  components: {
    PlayerLive,
    PlayerLiveChapter,
    LiveInforModal
  },
  data() {
    return {
      currentLive: null
    }
  },
  props: {
    apiRoute: null,
    transifex: null,
    webSocketUrl: null,
    siteName: null,
    pollActive: {
      type: String,
      default: null
    },
  },
  async mounted() {
    await this.getCurrentLive()
    const subscribeAction = {action: "subscribe", payload: "brahms.live"};
    brahmsWebSocket(this.webSocketUrl, subscribeAction, this.onMessageWSCallBack)
  },
  methods: {
    onMessageWSCallBack(event) {
      const updatedLiveId = JSON.parse(event.data).live_id
      const site = JSON.parse(event.data).site
      // Get a random timeout between 10000 -> 15000 miliseconds
      // For limit the request comme all in same time
      let timeout = Math.floor(((Math.random() * 10 + 1)/2 + 10)* 1000)
      if (site === this.siteName) {
        if (this.currentLive) {
          if (this.currentLive.id === updatedLiveId)
            setTimeout(async () => await this.getCurrentLive()
                , timeout);
        } else {
          // No currentLive -> call api /lives
          setTimeout(async () => await this.getCurrentLive()
              , timeout);
        }
      }
    },
    async getCurrentLive() {
      const {data} = await axios.get(this.apiRoute);
      if (data.count > 0) {
        this.currentLive = data.results[0]
        this.$store.commit('set_isHomeHeaderCarousel', false)
      } else {
        this.currentLive = null
        this.$store.commit('set_isHomeHeaderCarousel', true)
      }
    }
  },
}
</script>

<style lang="scss">
.player-chapter {
  position: absolute;
  left: 0;
  right: 0;
}
</style>