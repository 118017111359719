
/** ********************** PauseAbleTimer ************************/
class PauseAbleTimer {
  constructor(callback, delay) {
    this.callback = callback
    this.remainingTime = delay
    this.startTime = ""
    this.timerId = ""
    this.isTimerDone = false
    this.isTimerPaused = false
    this.isTimerStarted = false
  }

  startTimer() {
    if (!this.isTimerStarted) {
      this.startTime = new Date()
      this.timerId = setTimeout(this.callback, this.remainingTime)
      this.isTimerStarted = true
    }
  }

  pauseTimer() {
    if (this.isTimerStarted && !this.isTimerDone) {
      clearTimeout(this.timerId)
      this.remainingTime -= new Date() - this.startTime
      this.isTimerPaused = true
    }
  }

  resumeTimer() {
    if (this.isTimerStarted && this.isTimerPaused && !this.isTimerDone) {
      this.startTime = new Date()
      clearTimeout(this.timerId)
      this.timerId = setTimeout(this.callback, this.remainingTime)
      this.isTimerPaused = false
    }
  }

  clearTimer() {
    if (this.isTimerStarted && !this.isTimerDone) {
      clearTimeout(this.timerId)
      this.isTimerDone = true
    }
  }
}

let appUtils = {
    updateFilter(selectedFilter, queryParam) {
      if(selectedFilter.length > 0) {
        this.insertUrlParam(queryParam, selectedFilter.toString())
      }
      else {
        this.removeUrlParameter(queryParam)
      }
    },
    insertUrlParam(key, value) {
      if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newUrl}, '', newUrl);
      }
    },
    removeUrlParameter(paramKey) {
      const url = window.location.href
      let r = new URL(url)
      r.searchParams.delete(paramKey)
      const newUrl = r.href
      window.history.pushState({path: newUrl}, '', newUrl)
    },
    countdown(dateToCount, documentID, transifex, callbackAt0, noDays=false) {
      // Set the date we're counting down to
      let countDownDate = new Date(dateToCount).getTime();
      // Update the count down every 1 second
      let countDown = setInterval(() => {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        let txDay = days < 2 ? transifex.countdown.day : transifex.countdown.days;
        let txHour = hours < 2 ? transifex.countdown.hour : transifex.countdown.hours;
        let txMinute = minutes < 2 ? transifex.countdown.minute : transifex.countdown.minutes;
        let txSecond = seconds < 2 ? transifex.countdown.second : transifex.countdown.seconds;

        try {
          if (noDays) {
            // Display the result in the element with id="demo"
            document.getElementById(documentID).innerHTML = hours + txHour + " "
                + minutes + txMinute + " " + seconds + txSecond + " ";
          } else {
            // Display the result in the element with id="demo"
            document.getElementById(documentID).innerHTML = days + txDay + " " + hours + txHour + " "
                + minutes + txMinute + " " + seconds + txSecond + " ";
          }
        } catch (error) {
          console.log(minutes)
        }


        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(countDown);
          document.getElementById(documentID).innerHTML = "";
          callbackAt0()
        }
      }, 1000);
    }
}

export { PauseAbleTimer, appUtils }
