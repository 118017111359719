
function brahmsWebSocket( url, subscribeAction, onMessageCallBack) {
	const brahmsWS = new WebSocket(url);

	brahmsWS.addEventListener('message', function (event) {
		onMessageCallBack(event);
		// console.log("brahmsWebSocket - On message:", event.data);
		console.log("brahmsWebSocket - On message");
	});

	brahmsWS.addEventListener('open', () => {
		console.log('brahmsWebSocket - Connection opened - ');
		// Subscribe to the topic after Connection opened 1 secs
		setTimeout(() => {
				brahmsWS.send(JSON.stringify(subscribeAction));
				console.log("brahmsWebSocket - subscribe OK");
			}
			, 1000);
	});

	brahmsWS.addEventListener('close', (event) => {
		console.log('brahmsWebSocket The connection has been closed.', event);
		setTimeout( () => {
			console.log('brahmsWebSocket try to reconnect');
			brahmsWebSocket(url, subscribeAction, onMessageCallBack);
		}, 1000);
	});

	brahmsWS.addEventListener('error', function (event) {
		console.log('brahmsWebSocket error: ', event);
	});

	// Ping every 8 mins for maintenance server connection
	// if not server communication, connection will closed after 10 mins
	setInterval(() => {
		brahmsWS.send("ping")
	}, 8 * 60 * 1000)
}

export { brahmsWebSocket }