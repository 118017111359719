<template>
  <div>
    <div v-if="performance.video_file?.url" style="position: relative">
      <div id="newsletter-overlay" class="newsletter-overlay">
        <div class="cta-player-container myScrollbar overflow-auto">
          <PlayerNewsletter :transifex="transifex"/>
        </div>
      </div>
      <div :id="id"></div>
    </div>
    <div v-else class="relative flex w-full justify-center">
      <!-- Replay coming soon -->
      <img :src="performance.video_image" alt="performance.video_image" class="object-cover w-full">
      <div class="absolute inset-0 flex items-end" style="background-color: rgba(0, 0, 0, 0.2);">
        <p class="mb-4 ml-4 text-base text-white font-bold lg:text-[22px]">Replay coming soon</p>
      </div>
    </div>
  </div>
</template>

<script>
import {JwplayerViewManager, PlayerUtils} from "../../js/medici_player";
import PlayerNewsletter from "@/components/player/PlayerNewsletter";
export default {
  name: "player-replay",
  components:  {
    PlayerNewsletter
  },
  props: {
    performance: null,
    transifex: null
  },
  data() {
    return {
      id: "player-brahms",
      jwplayerView: new JwplayerViewManager()
    }
  },
  computed: {
  },
  mounted() {
    if (this.performance.video_file?.url) {
      this.$store.commit('set_player', this.id)
      const additionalSettings = {
        playlist: PlayerUtils.formatPlayListRePlay(this.performance),
      }
      additionalSettings['advertising'] = {
        "client": "vast",
        "schedule": '/en/vmap/',
        "rules": {
          "frequency": 0, // Pub only on 1st element of playlist
        },
      }
      let movieGtmData = this.getGtmData()
      let movieAudienceData = this.getAudienceData()
      this.$store.commit('start_player', {additionalSettings, movieGtmData, movieAudienceData})
      this.$store.dispatch('observerPlayerEvents')

      let player = this.$store.state.playerInstance
      this.jwplayerView.addNewsletterOverlay(player)

      const jwplayerCues = this.getJwplayerCues(this.performance);
      if (jwplayerCues.length > 0)
        this.$store.state.playerInstance.addCues(jwplayerCues)
    }
  },
  methods: {
    getGtmData() {
      return {
        video_id: this.performance.id,
        video_name: this.performance.title,
        video_category: 'piano',
        video_type: "replay",
        video_url: this.performance.url
      }
    },
    getAudienceData() {
      return {
        id: this.performance.id,
        slug: this.performance.slug,
        type: "replay",
        category: "piano",
      }
    },
    getJwplayerCues(performance) {
      const jwplayerCues = [];
      /** Loop all chapter to get cue and forbiddenChapters **/
      if (performance.works_movements) {
        performance.works_movements.forEach((chapter) => {
          if (chapter.musical_work_movement) {
            chapter.musical_work_movement.forEach((movement) => {
              if (movement.timecode) {
                jwplayerCues.push({
                  begin: movement.timecode,
                  cueType: "custom",
                  text: chapter.musical_work.title + ' - ' + movement.title
                })
              }
            })
          } else {
            if (chapter.musical_work.timecode) {
              jwplayerCues.push({
                begin: chapter.musical_work.timecode,
                cueType: "custom",
                text: chapter.musical_work.title
              })
            }
          }
        })
        return jwplayerCues
      }
    }
  }
}
</script>

<style lang="scss">
.jw-slider-time .jw-cue-type-custom {
  background-color: #31AEE4 !important;
}
.jw-tooltip-time .jw-overlay {
  bottom: 0;
  min-height: 0;
  width: 150px !important;
}
</style>