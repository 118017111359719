<template>
  <div v-if="performance">
    <PlayerReplay
      :performance="performance"
      :transifex="transifex"
    />
  </div>
<!--  <pre>{{performance}}</pre>-->
</template>

<script>
import axios from "axios";
import PlayerReplay from "@/components/player/PlayerReplay";

export default {
  name: "performance-embed-performance",
  props: {
    apiRoute: null,
    transifex: null
  },
  components: {
    PlayerReplay,
},
  data() {
    return {
      performance: null,
    }
  },
  async beforeCreate() {
    const {data} = await axios.get(this.apiRoute);
    if (data.performance?.id) {
      this.performance = data.performance
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
</style>