<template>
  <div v-if="recommendations" class="pt-7 md:pt-10">
    <div class="myContainer flex justify-between items-baseline pb-3 lg:pb-8">
      <h2 v-html="transifex.medicitvMovies.title"></h2>
      <a href="https://www.medici.tv/en/concerts/?instrument=piano" target="_blank" class="hidden text-black font-bold text-xl hover:text-primary sm:inline-block" v-html="transifex.medicitvMovies.seeAll"></a>
    </div>
    <swiper 
      :navigation="true" 
      :modules="modules"
      slides-per-view="auto"
      :space-between="35"
      :breakpoints="swiperOptions.breakpoints"
      class="small-slide"
  >
    <swiper-slide v-for="(item, index) in recommendations" :key="index">
      <a :href="item.url" target="_blank" class="group relative inline-block w-full">
        <img :src="item.image" class="object-cover w-full h-40 md:h-52 xl:h-64" alt="carousel.image">
        <div class="absolute inset-0 opacity-0 flex items-center justify-center transition-all ease-in-out group-hover:opacity-100" :style="{backgroundColor: 'rgba(17, 110, 150, .7)'}">
          <p class="text-base font-bold lg:text-[22px]" v-html="transifex.medicitvMovies.seeMore"></p>
        </div>
      </a>

      <h3 v-if="item.title">{{item.title}}</h3>
      <p class="text-black text-xs md:text-base lg:text-xl">{{item.subtitle}}</p>
    </swiper-slide>
  </swiper>
  </div>
</template>

<script>

import axios from "axios";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide} from 'swiper/vue';

export default {
  name: "medicitv-movie",
  props: {
    apiRoute: null,
    transifex: null
  },
  data() {
    return {
      recommendations: null,
      swiperOptions: {
        breakpoints: {       
          320: {       
            slidesPerView: 'auto',
            spaceBetween: 10     
          },          
          520: {       
            slidesPerView: 2,       
            spaceBetween: 20     
          },
          1024: {       
            slidesPerView: 3,       
            spaceBetween: 35     
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 35
          },
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  computed: {
  },
  async beforeCreate() {
    const {data} = await axios.get(this.apiRoute);
    if (data.recommendations.length > 0) {
      this.recommendations = data.recommendations
    }
  },
  mounted() {
  }
}
</script>

<!--<style lang="scss" scoped>-->
<!--.swiper:deep() {-->
<!--  .swiper-slide {-->
<!--    width: 80%;-->
<!--    -->
<!--    @media only screen and (min-width: 520px) {-->
<!--      width: 40% !important;-->
<!--      max-width: 400px;-->
<!--    }-->

<!--    @media only screen and (min-width: 1024px) {-->
<!--      width: 30% !important;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->