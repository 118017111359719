<template>
  <div id="modalLiveInfor" class="modal-live-info">
    <div class="px-2 md:px-4 pt-2 md:pt-3">
      <div class="text-base font-bold uppercase"><i class="fas fa-bolt"></i> {{transifex.common.liveUpdate}}</div>
      <div v-if="currentLive" class="text-sm md:pt-1">{{currentLive.live_update}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "live-info-modal",
  components: {
  },
  props: {
    transifex: null,
    currentLive: null
  },
  methods: {
  },
  mounted() {
    // Get the modal
    let modal = document.getElementById("modalLiveInfor");

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }
}
</script>

<style lang="scss">
.modal-live-info {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  background-color: #E94047;
  z-index: 100;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0;
  width: 585px;
  height: 78px;
  box-shadow: 0 0 12px rgba(77, 77, 77, 0.7);
  border-radius: 9px;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 0.8s;
  animation-name: animatebottom;
  animation-duration: 0.8s;
}

@media only screen and (max-width: 768px) {
    .modal-live-info {
        width: 80%;
    }
}

@media only screen and (max-width: 425px) {
    .modal-live-info {
        width: 90%;
    }
}

/* Add Animation */
@-webkit-keyframes animatebottom {
  from {bottom:0; opacity:0}
  to {bottom:10px; opacity:1}
}

@keyframes animatebottom {
  from {bottom:0; opacity:0}
  to {bottom:10px; opacity:1}
}


.modal-header {
  padding: 2px 16px;
}

.modal-body {padding: 2px 16px;}

.modal-footer {
  padding: 2px 16px;
}
</style>