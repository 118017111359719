import { GtmPlayerCollector } from "./gtm_manager"
import { StatsCollector, AudienceCollector, PlayerErrorCollector } from "./data_collector"

class PlayerCollector {
  constructor(playerInstance) {
    this.playerInstance = playerInstance
  }

  setupStatsCollector(movieGtmData, movieAudienceData) {
    const statsCollector = new StatsCollector(this.playerInstance)
    const audienceCollector = new AudienceCollector(this.playerInstance, movieAudienceData)
    const gtmPlayerCollector = new GtmPlayerCollector(this.playerInstance)
    const playerErrorCollector = new PlayerErrorCollector(this.playerInstance)
    playerErrorCollector.startCollect()

    this.playerInstance.on("firstFrame", function (firstFrame) {
      gtmPlayerCollector.startCollect(movieGtmData)
      statsCollector.startCollect(firstFrame)
      audienceCollector.startCollect(movieAudienceData)
      audienceCollector.triggerAudiencePLAY()
    })

    this.playerInstance.on("buffer", function (buffer) {
      statsCollector.collectBuffering(buffer)
    })

    this.playerInstance.on("visualQuality", function (quality) {
      statsCollector.collectBitrate(quality)
    })

    this.playerInstance.on("play", function () {
      /* Fire when player back in state playing: after pause or after buffering
       *  or on the first playing */
      gtmPlayerCollector.timersCollector.forEach(function (timer) {
        timer.resumeTimer()
      })
      statsCollector.timersCollector.forEach(function (timer) {
        timer.resumeTimer()
      })
      audienceCollector.timersCollector.forEach(function (timer) {
        timer.resumeTimer()
      })
      audienceCollector.triggerAudienceSACEM()
      audienceCollector.triggerAudienceRESUME()

      // Disable temporary gtm sacem in collector, use event sacem in JW Player - Available variable of GTM
      gtmPlayerCollector.triggerGtmSACEM()
    })

    this.playerInstance.on("pause", function () {
      gtmPlayerCollector.timersCollector.forEach(function (timer) {
        timer.pauseTimer()
      })
      statsCollector.timersCollector.forEach(function (timer) {
        timer.pauseTimer()
      })
      audienceCollector.timersCollector.forEach(function (timer) {
        timer.pauseTimer()
      })
      audienceCollector.triggerAudiencePAUSE()
    })

    this.playerInstance.on("remove", function () {
      /* Fire when player is remove ex: change item in carousel, change page */
      gtmPlayerCollector.timersCollector.forEach(function (timer) {
        timer.clearTimer()
      })
      statsCollector.timersCollector.forEach(function (timer) {
        timer.clearTimer()
      })
      audienceCollector.timersCollector.forEach(function (timer) {
        timer.clearTimer()
      })
      audienceCollector.triggerAudienceSTOP()
      audienceCollector.clearTriggerIntervalVU1M()
    })

    this.playerInstance.on("idle", function () {
      /* Fire when player stop or player pass to next item in jwplayer playlist[] */
      gtmPlayerCollector.timersCollector.forEach(function (timer) {
        timer.clearTimer()
      })
      statsCollector.timersCollector.forEach(function (timer) {
        timer.clearTimer()
      })
      audienceCollector.timersCollector.forEach(function (timer) {
        timer.clearTimer()
      })
      audienceCollector.clearTriggerIntervalVU1M()
    })

    this.playerInstance.on("playlistComplete", function () {
      /* We are using 1st item of playlist for preroll, So the real complete is playlistComplete */
      audienceCollector.triggerAudienceEND()
      audienceCollector.clearTriggerIntervalVU1M()
    })
  }
}
export { PlayerCollector }
