<template>
  <div v-if="performanceItems.length > 0" class="pt-7 md:pt-10 animate__animated animate__fadeIn animate__delay-1s">
    <div
      :class="`myContainer flex items-baseline pb-3 lg:pb-8
      ${performanceItems.length > 1 ? 'justify-between' : 'justify-center'}`"
    >
      <h2 v-html="transifex.latestReplays.title"></h2>
      <a
        :href="'/performances/'"
        :class="`hidden text-black font-bold text-xl hover:text-primary 
        ${performanceItems.length > 2 ? 'sm:inline-block' : ''}`"
        v-html="transifex.latestReplays.seeAll"></a
      >
    </div>
    <div
      v-if="performanceItems.length > 1"
      :class="`${performanceItems.length === 2 ? 'ml-8 xl:ml-44' : ''}`"
    >
      <swiper
        :navigation="true"
        :pagination="{ clickable: true }"
        :modules="modules"
        slides-per-view="auto"
        :centeredSlides="performanceItems.length > 2 ? true : false"
        :loop="performanceItems.length > 2 ? true : false"
        :breakpoints="swiperOptions.breakpoints"
      >
        <swiper-slide v-for="(item, index) in performanceItems" :key="index">
          <ReplayCard :item="item" />
        </swiper-slide>
      </swiper>
    </div>

    <div v-else class="max-w-2xl mx-auto px-8 pb-3 md:px-0">
      <ReplayCard :item="performanceItems[0]" />
    </div>
  </div>
  <!--  <pre>{{performanceItems}}</pre>-->
<!--  <pre>{{translation.text}}</pre>-->
</template>

<script>
import axios from "axios";
import "vue-select/dist/vue-select.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import ReplayCard from "./ReplayCard.vue";
import "swiper/css";

export default {
  name: "latest-replay",
  props: {
    apiRoute: null,
    transifex: null
  },
  components: {
    Swiper,
    SwiperSlide,
    ReplayCard,
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
  data() {
    return {
      performanceItems: [],
      swiperOptions: {
        breakpoints: {
          320: {
            spaceBetween: 10,
          },
          520: {
            spaceBetween: 20,
          },
          1024: {
            spaceBetween: 35,
          },
        },
      },
    };
  },
  computed: {},
  async beforeCreate() {
    const { data } = await axios.get(this.apiRoute);
    if (data.count > 0) {
      this.performanceItems = data.results;
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.swiper:deep() {
  @media only screen and (min-width: 768px) {
    padding-bottom: 50px;
  }

  .swipper-wrapper {
    overflow: hidden;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @media only screen and (min-width: 768px) {
      height: 16rem;
    }
    @media only screen and (min-width: 1280px) {
      height: 24rem;
    }
  }

  .swiper-slide {
    width: calc(100% - 100px);

    @media only screen and (min-width: 520px) {
      width: 50% !important;
      max-width: 662px;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    .swiper-pagination-bullet {
      display: inline-block;
      width: 21px;
      height: 21px;
      margin: 0 14px;
      border: 1px solid #c4c4c4;
      border-radius: 100%;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background-color: #c4c4c4;
      }
    }
  }
}
</style>
