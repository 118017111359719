<template>
  <div v-if="performance" class=" px-6 py-3.5 absolute inset-0">
    <div class="pb-1 font-bold text-primary text-xs md:text-base lg:text-xl">{{transifex.playerChapter.program}}</div>
    <div class="pb-2 font-bold">{{performance.title}}</div>
    <div v-for="(item, index) in performance.works_movements" :key="index" class="pb-2 px-6">
      <p class="font-bold text-primary">{{item.artists}}</p>

      <div v-if="!!item.musical_work_movement">
        <p v-if="item.musical_work" class="text-xs md:text-sm">{{item.musical_work.title}}</p>
      </div>
      <div v-else>
        <p v-if="item.musical_work"
           class="text-xs md:text-sm chapter-element"
           :class="{
              'cursor-pointer text-underline': item.musical_work.timecode,
              'active': isCurrentMovement(item.musical_work.timecode, playingTime)
            }"
           @click="playerSeek(item.musical_work.timecode)">
          <span>{{item.musical_work.title}}</span>
        </p>
      </div>

      <ul>
        <li v-for="(itemX, index) in item.musical_work_movement" :key="index" @click="playerSeek(itemX.timecode)"
            class="px-6 text-xs md:text-sm chapter-element"
            :class="{
              'cursor-pointer text-underline': itemX.timecode,
              'active': isCurrentMovement(itemX.timecode, playingTime)
             }"
        >
          <span>{{itemX.title}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "player-replay-chapter",
  components:  {

  },
  props: {
    performance: null,
    transifex: null
  },
  data() {
    return {
      id: "",
      timeCodeList: this.getTimeCodeList()
    }
  },
  computed: {
    playingTime() {
      return this.$store.state.playingTime
    }
  },
  mounted() {
  },
  methods: {
    playerSeek(timeCode) {
      if (timeCode) {
        this.$store.state.playerInstance.seek(parseInt(timeCode))
      }
    },
    getTimeCodeList() {
      let timeCodes = []
      if(this.performance && this.performance.works_movements) {
        for (let i = 0; i < this.performance.works_movements.length; i++) {
          timeCodes.push(this.performance.works_movements[i].musical_work.timecode)
          if (this.performance.works_movements[i].musical_work_movement) {
            for (let j = 0; j < this.performance.works_movements[i].musical_work_movement.length; j++) {
              timeCodes.push(this.performance.works_movements[i].musical_work_movement[j].timecode);
            }
          }
        }
      }
      return [...new Set(timeCodes)]
    },
    isCurrentMovement(timeCode, playingTime) {
      if (this.timeCodeList[0] === null) return false
      let lastTimeCode = this.timeCodeList[0]
      let nextTimeCode = this.timeCodeList[this.timeCodeList.length - 1]
      for (let i = 0; i < this.timeCodeList.length; i++) {
        if (timeCode > this.timeCodeList[i]) {
          lastTimeCode = this.timeCodeList[i]
        }
        if (timeCode < this.timeCodeList[i]) {
          nextTimeCode = this.timeCodeList[i]
          break
        }
      }
      if (playingTime >= timeCode && timeCode === this.timeCodeList[this.timeCodeList.length - 1]) return true
      if (playingTime >= nextTimeCode) return false;
      return playingTime >= timeCode && nextTimeCode > timeCode && lastTimeCode <= timeCode;
    }
  }
}
</script>

<style lang="scss">
.text-underline:hover {
  text-decoration: underline !important;
  -webkit-text-decoration-color: #31AEE4 !important; /* Safari */
  text-decoration-color: #31AEE4 !important;
}

.chapter-element {
  position: relative;
  cursor: pointer;

  &.active {
    span {
      position: relative;
      display: inline-block;

      &::before {
        position: absolute;
        top: 4px;
        left: -14px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;

        border-left: 10px solid #31AEE4;
        content: "";
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #31AEE4;
        content: "";
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
</style>