<template>
  <div id="modalTimeZone" class="modal-time-zone">
    <div class="modal-time-zone-content">
      <div v-if="timezoneNames" style="color: black">
                  <v-select
                    :placeholder="transifex.common.selectTimeZone"
                    :modelValue="selectedTimeZone"
                    :options="timezoneNames"
                    :filterable="true"
                    @update:modelValue="updateTimeZone"
                    class="sm:col-span-2 md:col-span-1"
          >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="mr-3">
              <img src="@/assets/vselect-arrow.svg" class="w-4 h-4 flex-shrink-0 cursor-auto" alt="vselect-arrow.svg">
            </span>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>

// import moment from 'moment';
import moment from 'moment-timezone';
import vSelect from "vue-select";

export default {
  name: "time-zone-modal",
  components: {
    vSelect
  },
  props: {
    transifex: null
  },
  data() {
    return {
      timezoneNames: null,
      selectedTimeZone: null
    }
  },
  methods: {
    updateTimeZone(value) {
      this.selectedTimeZone = value
      let modal = document.getElementById("modalTimeZone");
      modal.style.display = "none";
      this.$emit("changeTimeZone", value);
    }
  },
  mounted() {
    this.timezoneNames = moment.tz.names();
    // Get the modal
    let modal = document.getElementById("modalTimeZone");

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }
}
</script>

<style lang="scss">
/* The Modal (background) */
.modal-time-zone {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 25; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 120px;
}

/* Modal Content */
.modal-time-zone-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;

  @media only screen and (min-width: 1024px) {
    width: 30%;
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close-modal-time-zone {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-modal-time-zone:hover,
.close-modal-time-zone:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  //background-color: #5cb85c;
  //color: white;
}

.modal-body {padding: 2px 16px;}

.modal-footer {
  padding: 2px 16px;
  //background-color: #5cb85c;
  //color: white;
}
</style>