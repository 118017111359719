<template>
  <div
      v-if="chatId"
    class="live-center-embed"
    :data-src="
      'https://livecenter.norkon.net/frame/medicitv/' + chatId + '/white'
    "
  ></div>
</template>

<script>
export default {
  name: "MovieLiveChat",
  props: {
    chatId: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.initLiveChat()
  },
  methods: {
    initLiveChat() {
      (function (n) {
        function c(t, i) {
          n[e](h, function (n) {
            var r, u;
            if (n && (r = n[n.message ? "message" : "data"] + "", r && r.substr && r.substr(0, 3) === "nc:") && (u = r.split(":"), u[1] === i)) switch (u[2]) {
              case"h":
                t.style.height = u[3] + "px";
                return;
              case"scrolltotop":
                t.scrollIntoView();
                return
            }
          }, !1)
        }

        for (var t, u, f, i, s, e = n.addEventListener ? "addEventListener" : "attachEvent", h = e === "attachEvent" ? "onmessage" : "message", o = n.document.querySelectorAll(".live-center-embed"), r = 0; r < o.length; r++) (t = o[r], t.getAttribute("data-rendered")) || (u = t.getAttribute("data-src"), u && (t.setAttribute("data-rendered", "true"), f = n.ncVizCounter || 1e3, n.ncVizCounter = f + 1, i = f + "", s = "nc-frame-c-" + i, t.innerHTML = '<div style="position: relative; height: 300px; border: none;" id="' + s + '"><iframe src="' + u + "?" + i + '" style="position: absolute; left: 0; top: 0; width: 100%; height: 100%;" frameborder="0" allowtransparency="true" allowfullscreen="true" id="live-chat-medici" webkitallowfullscreen="true" mozallowfullscreen="true" allow="autoplay; fullscreen"></iframe></div>', c(t.firstChild, i)))
      })(window);
    },
  }
}
</script>

<style lang="scss">
</style>
