
function formatNewsletterSchema(email, isMediciNewsletter = false, movie_id="", custom_data="") {
	return  {
		"newsletter_subscription": {
			"email": email,
			"optins": isMediciNewsletter ? ['MEDICITV_EDITO', 'MEDICITV_CLIBURN']
				: ['MEDICITV_CLIBURN'], // MEDICITV_MKT, MEDICITV_EDITO, PARTNER,TCH, MEDICITV_CLIBURN,
			"optin_date": new Date().toISOString(),
			"preferences": "", // Dance, Documentary
			"referrer": {
				"site": window.location.hostname,
				"url": window.location.href,
				"lang": document.documentElement.lang,
				"movie": movie_id // medicitv:5434 or leeds2021:123
			},
			"aquisition_data": {
				"medium": "",
				"campaign": "",
				"term": "",
				"content": ""
			},
			"custom_data": custom_data,
			"lang": navigator.language,
			"tz": Intl.DateTimeFormat().resolvedOptions().timeZone
		}
	}
}

export { formatNewsletterSchema }